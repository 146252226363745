<template>
  <Table
    :loading="loading"
    :table-cols="tableCols"
    :table-data="tableData"
    :table-btns="tableBtns"
    :show-pagination="false"
  >
    <template v-slot="{ col, row }">
      <div v-if="col.prop === 'map'" class="flex-c">
        <img class="cursor" src="@/assets/images/common/tansport.svg" @click="openMap(row)">
      </div>
      <div v-if="col.prop === 'reportPrice'">
        <span class="mr5">{{ $parseNumber(row.qxReportPrice) }}{{ col.rulesObj[+row.priceUnit]||'-' }}</span>
        <span :class="$CONSTANT.taxColorMap[+row.needTax]">{{ $CONSTANT.taxMap[+row.needTax]||'-' }}</span>
      </div>
      <div v-if="col.prop === 'lineName'">
        <TextToolTip :text="`${row.loadAddrName||'-'}-${row.unloadAddrName||'-'}`" placement="none" />
      </div>
      <div v-if="col.prop === 'realLoadNum'">
        {{ numberCalculation(row.realLoadNum,1,1000,'div') }}吨 {{ row.realLoadCarNum }}车
      </div>
      <div v-if="col.prop === 'realUnLoadNum'">
        {{ numberCalculation(row.realUnLoadNum,1,1000,'div') }}吨 {{ row.realUnLoadCarNum }}车
      </div>
      <div v-if="col.prop === 'loss'">
        {{ numberCalculation(row.realLoadNum,row.realUnLoadNum,1000,'minus') }}吨
        <!-- {{ numberCalculation(row.loadCarCount,row.unloadCarCount,1,'minus') }}车 -->
      </div>
      <div v-if="col.prop === 'sourceType'" class="ellipsis">
        <span>平台定向</span>
      </div>
    </template>
  </Table>
</template>

<script>
import { parseTime, numberCalculation } from '@/utils';
import wayBill from '@/api/transport/wayBill';
export default {
  filters: {
    parseTime,
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    searchForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      numberCalculation,
      loading: false,
      tableBtns: [
        {
          label: '导出',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
      ],
      tableCols: ['PT005'].includes(this.$store.state.common.AUV_ROLE_CODE) ? [
        { label: '货单编号', prop: 'goodsSerial', fixed: 'left' },
        { label: '运单编号', prop: 'transSerial', fixed: 'left', type: 'jump', evt: (data) => { this.toDetail(data); } },
        { label: '履约动态', prop: 'map', type: 'customize', width: '85' },
        { label: '承接价格', prop: 'reportPrice', type: 'customize', width: '130', rulesObj: this.$CONSTANT.transportPriceUnitMap.O },
        { label: '承运人', prop: 'createUsername' },
        { label: '产品名称', prop: 'goodsName' },
        { label: '线路名称', type: 'customize', prop: 'lineName', width: '155' },
        // { label: '确认车数', prop: 'total' },
        { label: '实际装货', prop: 'realLoadNum', type: 'customize' },
        { label: '实际卸货', prop: 'realUnLoadNum', type: 'customize' },
        { label: '损耗', prop: 'loss', type: 'customize' },
        { label: '报车渠道', prop: 'sourceType', type: 'customize', rulesObj: this.$CONSTANT.sourceMap },
        { label: '装货地址', prop: 'loadAddrRegion', width: '165' },
        { label: '卸货地址', prop: 'unloadAddrRegion', width: '165' },
        { label: '货品单价（元）', prop: 'price', type: 'numberTransition', multiple: 100 },
        // 运单状态:1 待报车 2 已报车 3 货主持确认(竞价跳过) 4 待运输 5 运输中 6 已完成(所有车单运输完成) 0 已取消
        // 1待报车、2已报车、3待运输、4运输中、5已完成、0已取消
        { label: '运单状态', prop: 'state', type: 'transition', rulesObj: this.$CONSTANT.wayBillStateMap.O },
      ] : [
        { label: '货单编号', prop: 'goodsSerial', fixed: 'left', type: 'jump', evt: (data) => { this.toGoodsBillDetail(data); } },
        { label: '运单编号', prop: 'transSerial', fixed: 'left', type: 'jump', evt: (data) => { this.toDetail(data); } },
        { label: '履约动态', prop: 'map', type: 'customize', width: '85' },
        { label: '承接价格', prop: 'reportPrice', type: 'customize', width: '130', rulesObj: this.$CONSTANT.transportPriceUnitMap.O },
        { label: '承运人', prop: 'createUsername' },
        { label: '产品名称', prop: 'goodsName' },
        { label: '线路名称', type: 'customize', prop: 'lineName', width: '155' },
        { label: '确认车数', prop: 'total' },
        { label: '实际装货', prop: 'realLoadNum', type: 'customize' },
        { label: '实际卸货', prop: 'realUnLoadNum', type: 'customize' },
        { label: '损耗', prop: 'loss', type: 'customize' },
        { label: '报车渠道', prop: 'sourceType', type: 'customize', rulesObj: this.$CONSTANT.sourceMap },
        { label: '装货地址', prop: 'loadAddrRegion', width: '165' },
        { label: '卸货地址', prop: 'unloadAddrRegion', width: '165' },
        { label: '货品单价（元）', prop: 'price', type: 'numberTransition', multiple: 100 },
        // 运单状态:1 待报车 2 已报车 3 货主持确认(竞价跳过) 4 待运输 5 运输中 6 已完成(所有车单运输完成) 0 已取消
        // 1待报车、2已报车、3待运输、4运输中、5已完成、0已取消
        { label: '运单状态', prop: 'state', type: 'transition', rulesObj: this.$CONSTANT.wayBillStateMap.O },
      ],
    };
  },
  methods: {
    toDetail({ id, gId }) {
      this.$router.push(`/transport/wayBill/detail?id=${id}&gId=${gId}`);
    },
    toGoodsBillDetail(data) {
      if (data.gId) {
        this.$router.push(`/transport/goods/detail?id=${data.gId}`);
      }
    },
    // 跳转运力监控
    openMap(data) {
      if (data.id) {
        this.$router.push(`/transport/monitor?id=${data.id}`);
      }
    },
    // 前端导出excel
    onExport() {
      let newState;
      if (this.searchForm.state && this.searchForm.state.length) {
        const stateString = this.searchForm.state.join(',');
        newState = stateString.replace(/1/g, '1,2').replace(/3/g, '3,4');
      } else {
        newState = null;
      }
      const params = {
        ...this.searchForm,
        state: newState,
        post: this.$store.state.common.AUV_ROLE_CODE,
      };
      wayBill.orderCarTransExport(params).then((res) => {
        const listData = res || [];
        if (!listData) {
          this.$baseMessage('暂无数据可导出!', 'error');
          return;
        }
        const header = ['承运商资质', '承运商姓名', '线路', '货主发单时间', '装货地址', '装货联系人', '卸货地址', '卸货联系人', '货物名称', '货物单价', '车队承运价格', '含税方式', '合理损耗(‰)', '计费方式', '结算方式', '平台上报时间', '车牌号', '司机姓名', '司机手机号', '司机身份证号', '运力标签', '装货吨数', '实际装货时间', '卸货吨数', '实际卸货时间', '损耗/吨', '车单状态', '货单编号', '运单编号', '车单编号'];
        const exportData = listData.map((item) => {
          return {
            '承运商资质': item.carCustomerName, // 承运商资质
            '承运商姓名': item.carCustomerUserName, // 承运商姓名
            '线路': item.lineName, // 线路
            '货主发单时间': item.goodOrderCreateTime, // 货主发单时间
            '装货地址': item.loadAddr, // 装货地址
            '装货联系人': item.loadContacter, // 装货联系人 李四 1771910166542
            '卸货地址': item.unloadAddr, // 卸货地址
            '卸货联系人': item.unloadContacter, // 卸货联系人 李四 1771910166542
            '货物名称': item.goodsName, // 货物名称
            '货物单价': item.prodPrice, // 货物单价	600元/吨?
            '车队承运价格': item.price, // 车队承运价格
            '含税方式': item.needTax, // 含税方式
            '合理损耗(‰)': item.permitLose, // 合理损耗(‰)
            // '货物单价': item.prodPrice, // 货物单价 元/吨?
            '计费方式': item.billing, // 计费方式 按卸货吨数
            '结算方式': item.settlement, // 结算方式 卸货后30天、磅单原件
            '平台上报时间': item.reportTime, // 平台上报时间
            '车牌号': item.truckNo, // 车牌号
            '司机姓名': item.driverName, // 司机姓名
            '司机手机号': item.driverPhone, // 司机手机号
            '司机身份证号': item.driverIdcode, // 司机身份证号
            '运力标签': item.tranTag, // 运力标签 黑货、白货
            '装货吨数': item.loadReceiptNum, // 装货吨数 33.92
            '实际装货时间': item.loadTime, // 实际装货时间 2024/8/13 18:36:25
            '卸货吨数': item.unloadReceiptNum, // 卸货吨数
            '实际卸货时间': item.unloadTime, // 实际卸货时间
            '损耗/吨': item.loss, // 损耗/吨 0
            '车单状态': item.state, // 车单状态 运输完成
            '货单编号': item.goodsSerial, // 货单编号
            '运单编号': item.transSerial, // 运单编号
            '车单编号': item.carSerial, // 车单编号
          };
        });
        const data = exportData.map((product) => {
          return Object.values(product);
        });
        const exportDate = parseTime(new Date().getTime(), 'YYYYMMDDHHmmss');
        import('@/utils/export2Excel').then((excel) => {
          excel.export_json_to_excel({
            header: header,
            data,
            filename: '运单明细',
            merges: ['A1:AD1'],
            autoWidth: true,
            bookType: 'xlsx',
            needTitle: true,
            fileNameJoin: exportDate,
          });
          this.$baseMessage('导出成功！', 'success');
        });
      }).catch(e => {
        console.log(e);
        this.$baseMessage('导出失败！', 'error');
      });
    },
  },
};

</script>
<style lang='scss' scoped>
</style>
